/**
 * Match media
 *
 */

export default function mediaQuery(mq) {
  const matches = (key) => {
    let mediaQueryList = window.matchMedia(key);
    mediaQueryList = mediaQueryList.matches;
    return mediaQueryList;
  };

  let key = mq;
  switch (key) {
    case 'xsmall':
      key = '(max-width: 479px)';
      break;
    case 'small':
      key = '(min-width: 480px)';
      break;
    case 'small-only':
      key = '(min-width: 480px) and (max-width: 767px)';
      break;
    case 'small-down':
      key = '(max-width: 767px)';
      break;
    case 'medium':
      key = '(min-width: 768px)';
      break;
    case 'medium-only':
      key = '(min-width: 768px) and (max-width: 1023px)';
      break;
    case 'medium-down':
      key = '(max-width: 1023px)';
      break;
    case 'large':
      key = '(min-width: 1024px)';
      break;
    case 'large-only':
      key = '(min-width: 1024px) and (max-width: 1279px)';
      break;
    case 'large-down':
      key = '(max-width: 1279px)';
      break;
    case 'xlarge':
      key = '(min-width: 1280px)';
      break;
    case 'xlarge-only':
      key = '(min-width: 1280px) and (max-width: 1439px)';
      break;
    case 'xlarge-down':
      key = '(max-width: 1439px)';
      break;
    case 'xxlarge':
      key = '(min-width: 1440px)';
      break;
    default:
      // eslint-disable-next-line no-console
      return console.error('Incorrect mediaQuery name.');
  }
  return matches(key);
}
