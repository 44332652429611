// import './assets';
import colorListInit from '../../../utils/colors-list';
import accordionInit from '../../components/accordion/accordion';
import Youtube from '../../components/youtube/youtube';
import sliderInit from '../../components/slider/slider';
import modalInit from '../../components/modal/modalInit';
import CookieConsent from '../../components/cookie-consent/cookie-consent';
import cookieOptionsSl from '../../components/cookie-consent/cookie-options-sl.json';
import cookieOptionsEn from '../../components/cookie-consent/cookie-options-en.json';
import galleryInit from '../../components/gallery/gallery';
import voting from '../../components/voting/voting';
import Navigation from '../../components/navigation/navigation';
import scrollbarInit from '../../components/scrollbar/scrollbar';

colorListInit();
accordionInit();
sliderInit();
modalInit();
galleryInit();
voting();
scrollbarInit();

// eslint-disable-next-line no-unused-vars
const tubes = new Youtube();
window.tubes = tubes;

// eslint-disable-next-line no-unused-vars
const navigation = new Navigation({ openOnHover: false });

// Cookie consent init
const { lang } = document.documentElement;
const cookieOptions = lang === 'sl' ? cookieOptionsSl : cookieOptionsEn;
const consent = new CookieConsent(cookieOptions); // eslint-disable-line no-unused-vars
