/**
 * Debounce
 *
 */

export default function debounce(callback, wait) {
  let timeout = null;

  return (...args) => {
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
  };
}
