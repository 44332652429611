/* global YT */

const defaults = {
  embedSelector: '.js-youtube-embed',
  playSelector: '.js-youtube-play',
  image: '.js-youtube-image',
  initOn: 'load',
};

export default class Youtube {
  constructor(options) {
    this.options = { ...defaults, ...options };
    this.embeds = Array.from(document.querySelectorAll(this.options.embedSelector));
    this.playerPrepared = false;
    this.players = [];
    this.init();
  }

  init() {
    this.embeds.forEach((embed) => {
      const play = embed.querySelector(this.options.playSelector);
      const id = embed.querySelector('[data-video-id]').dataset.videoId;

      if (this.options.initOn === 'load') {
        this.handler(id, embed);
        return;
      }

      if (this.options.initOn === 'click') {
        // prevent attaching more than one event listener
        if (!play.dataset.eventClick) {
          play.addEventListener('click', () => {
            this.handler(id, embed);
          });
          play.dataset.eventClick = true;
        }
      }
    });
  }

  handler(id, embed) {
    // prepare player
    this.preparePlayer();

    // waiting for YT to become ready
    const interval = setInterval(() => {
      if (typeof window.YT !== 'undefined' && window.YT.loaded === 1) {
        this.onYouTubeIframeAPIReady(id, embed);
        clearInterval(interval);
      }
    }, 10);
  }

  // Injecting script if not there already
  preparePlayer() {
    if (!this.playerPrepared) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/player_api';
      document.head.appendChild(tag);

      this.playerPrepared = true;
    }
  }

  onYouTubeIframeAPIReady(id, embed) {
    // eslint-disable-next-line no-unused-vars
    const player = new YT.Player(id, {
      // width: '640',
      // height: '360',
      playerVars: {
        // autoplay: 1,
      },
      videoId: id,
      events: {
        onReady: this.onPlayerReady.bind(this, embed),
      },
      host: 'https://www.youtube-nocookie.com', // NOTE: prevents `does not match the recipient window's origin`console error
    });
  }

  // image & play button
  onPlayerReady(embed, event) {
    this.players.push(event.target);
    const image = embed.querySelector(this.options.image);
    const play = embed.querySelector(this.options.playSelector);

    if (this.options.initOn === 'load') {
      // prevent attaching more than one event listener
      if (!play.dataset.eventClick) {
        play.addEventListener('click', () => {
          play.classList.add('-is-hidden');
          if (image) {
            image.classList.add('-fade-out');
          }
          event.target.playVideo();
        });
        play.dataset.eventClick = true;
      }
      return;
    }

    if (this.options.initOn === 'click') {
      play.classList.add('-is-hidden');

      if (image) {
        image.classList.add('-fade-out');
      }

      event.target.playVideo();
    }
  }

  stop() {
    this.players.forEach((player) => {
      player.pauseVideo();
    });
  }
}
