/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import Swiper, { Navigation, Pagination, Autoplay, A11y, Keyboard } from 'swiper';
import 'swiper/css/bundle';

Swiper.use([Navigation, Pagination, Autoplay, A11y, Keyboard]);

export default function sliderInit() {
  const sliderKeyvisual = document.querySelectorAll('.js-swiper-keyvisual');
  const sliderTimeline = document.querySelectorAll('.js-swiper-timeline');
  const sliderPersons = document.querySelectorAll('.js-swiper-persons');

  sliderKeyvisual.forEach((slider) => {
    let options = {
      updateOnWindowResize: true,
      watchOverflow: true,
      slidesPerView: 1,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      allowTouchMove: true,
      a11y: {
        enabled: true,
        prevSlideMessage: 'Naslednji',
        nextSlideMessage: 'Prejšnji',
        paginationBulletMessage: 'Na {{index}}',
      },
      keyboard: {
        enabled: true,
      },
      breakpoints: {
        1023: {
          allowTouchMove: false,
        },
      },
    };

    const pagination = slider.getAttribute('data-pagination');
    if (pagination) {
      options = {
        ...options,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'progressbar',
        },
      };
    }

    const navigation = slider.getAttribute('data-navigation');
    if (navigation) {
      options = {
        ...options,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      };
    }

    const autoplay = slider.getAttribute('data-autoplay');
    if (autoplay) {
      const autoplayDelay = slider.getAttribute('data-autoplay-delay');
      options = { ...options, autoplay: { delay: autoplayDelay, disableOnInteraction: false }, loop: true };
    }

    const singleSlider = new Swiper(slider, options);
  });

  sliderTimeline.forEach((slider) => {
    let options = {
      updateOnWindowResize: true,
      watchOverflow: true,
      slidesPerView: 1,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      a11y: {
        enabled: true,
        prevSlideMessage: 'Next',
        nextSlideMessage: 'Previous',
        paginationBulletMessage: 'To {{index}}',
      },
      keyboard: {
        enabled: true,
      },
      breakpoints: {
        460: {
          slidesPerView: 1.5,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        1023: {
          slidesPerView: 1.8,
          spaceBetween: 0,
        },
      },
    };

    const pagination = slider.getAttribute('data-pagination');
    if (pagination) {
      options = {
        ...options,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'progressbar',
        },
      };
    }

    const navigation = slider.getAttribute('data-navigation');
    if (navigation) {
      options = {
        ...options,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      };
    }

    const autoplay = slider.getAttribute('data-autoplay');
    if (autoplay) {
      const autoplayDelay = slider.getAttribute('data-autoplay-delay');
      options = { ...options, autoplay: { delay: autoplayDelay, disableOnInteraction: false }, loop: false };
    }

    const singleSlider = new Swiper(slider, options);
  });

  sliderPersons.forEach((slider) => {
    let options = {
      updateOnWindowResize: true,
      watchOverflow: true,
      slidesPerView: 1.2,
      spaceBetween: 4,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      a11y: {
        enabled: true,
        prevSlideMessage: 'Next',
        nextSlideMessage: 'Previous',
        paginationBulletMessage: 'To {{index}}',
      },
      keyboard: {
        enabled: true,
      },
      breakpoints: {
        460: {
          slidesPerView: 1.5,
        },
        768: {
          slidesPerView: 2.6,
        },
        1023: {
          slidesPerView: 4,
        },
      },
    };

    const pagination = slider.getAttribute('data-pagination');
    if (pagination) {
      options = {
        ...options,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'progressbar',
        },
      };
    }

    const navigation = slider.getAttribute('data-navigation');
    if (navigation) {
      options = {
        ...options,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      };
    }

    const autoplay = slider.getAttribute('data-autoplay');
    if (autoplay) {
      const autoplayDelay = slider.getAttribute('data-autoplay-delay');
      options = { ...options, autoplay: { delay: autoplayDelay, disableOnInteraction: false }, loop: false };
    }

    const singleSlider = new Swiper(slider, options);
  });
}
