// https://www.lightgalleryjs.com/

import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';

export default function galleryInit() {
  const galleries = Array.from(document.querySelectorAll('.js-gallery'));

  galleries.forEach((gallery) => {
    lightGallery(gallery, {
      plugins: [lgZoom, lgThumbnail],
      thumbnail: true,
    });
  });

  const videoGalleries = Array.from(document.querySelectorAll('.js-video-gallery'));

  videoGalleries.forEach((videoGallery) => {
    lightGallery(videoGallery, {
      plugins: [lgVideo],
    });
  });
}
