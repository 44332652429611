/* eslint-disable class-methods-use-this */
/* global tubes */

import focusTrap from 'focus-trap-js';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class Modal {
  constructor(config) {
    // Set elements
    this.elementOverlay = document.querySelector(config.selector);
    this.elementContent = this.elementOverlay.querySelector('[data-modal-content]');
    this.elementClose = this.elementOverlay.querySelector('[data-modal-close]');

    this.isOpened = false;
    this.addEventListeners();
  }

  open() {
    disableBodyScroll(this.elementContent);
    this.elementOverlay.setAttribute('aria-hidden', false);
    this.elementClose.focus();
    this.isOpened = true;

    const play = this.elementContent.querySelector('.js-youtube-play');
    if (play) {
      play.click();
    }

    const playUploadedVideo = this.elementContent.querySelector('video');
    if (playUploadedVideo) {
      playUploadedVideo.play();
    }
  }

  close() {
    enableBodyScroll(this.elementContent);
    this.elementOverlay.setAttribute('aria-hidden', true);
    this.isOpened = false;

    if (tubes) {
      tubes.stop();
    }

    const play = this.elementContent.querySelector('video');
    if (play) {
      play.pause();
    }
  }

  addEventListeners() {
    // For close element
    this.elementClose.addEventListener('click', (event) => {
      event.preventDefault();
      this.close();
    });

    // For overlay element
    this.elementOverlay.addEventListener('click', (event) => {
      if (event.target.hasAttribute('data-modal')) {
        this.close();
      }
    });

    // For key down
    document.addEventListener('keydown', (event) => {
      if (this.isOpened) {
        // Key esc or key space (when close button has focus)
        if (event.keyCode === 27 || (event.keyCode === 32 && document.activeElement === this.elementClose)) {
          this.close();
          // Key tab or shift+tab
        } else if (event.keyCode === 9) {
          // Focus trap for modal
          focusTrap(event, this.elementOverlay);
        }
      }
    });
  }
}
