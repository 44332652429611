/* eslint-disable no-bitwise */
function getColorVariables(styleSheets = document.styleSheets) {
  const colors = [];
  for (let i = 0; i < styleSheets.length; i++) {
    try {
      for (let j = 0; j < styleSheets[i].cssRules.length; j++) {
        try {
          for (let k = 0; k < styleSheets[i].cssRules[j].style.length; k++) {
            const name = styleSheets[i].cssRules[j].style[k];
            // test name for css variable signiture and uniqueness
            if (name.startsWith('--color') && colors.indexOf(name) === -1) {
              colors.push(name);
            }
          }
        } catch (error) {
          // error
        }
      }
    } catch (error) {
      // error
    }
  }
  return colors;
}

function getColorValues(colors, element = document.body) {
  const elStyles = window.getComputedStyle(element);
  const cssVars = {};
  for (let i = 0; i < colors.length; i++) {
    const key = colors[i];
    const value = elStyles.getPropertyValue(key);
    if (value) {
      cssVars[key] = value;
    }
  }
  return cssVars;
}

export default function colorListInit() {
  const container = document.querySelector('.styleguide-colors');

  if (container) {
    const cssVariables = getColorValues(getColorVariables(), document.documentElement);
    const colors = Object.entries(cssVariables);

    let html = '<div class="grid-auto">';

    colors.forEach((color) => {
      const colorName = color[0].replace('--color-', '');

      let hex = color[1].toString();
      const hexCode = hex.replace(' ', '');
      let borderClass = '';
      if (hexCode === '#fff') {
        borderClass = '-with-border';
      }

      hex = hexCode.replace('#', '');

      hex = +`0x${hex.slice(1).replace(hex.length < 5 && /./g, '$&$&')}`;

      const r = hex >> 16;
      const g = (hex >> 8) & 255;
      const b = hex & 255;

      const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      let textClass = '-white-text';
      if (hsp > 127.5) {
        textClass = '';
      }

      html += `<div class="styleguide-colors__color">
        <div class="color ${textClass} ${borderClass}" style="background-color:${hexCode};">
          <p>${colorName}</p>
          <p>${hexCode}</p>
        </div>
      </div>`;
    });

    html += '</div>';

    container.innerHTML = html;
  }
}
