/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
// https://www.npmjs.com/package/handorgel

import Handorgel from 'handorgel';

export default function accordionInit() {
  const accordions = document.querySelectorAll('.handorgel');

  accordions.forEach((accordion) => {
    let options = {
      initialOpenTransition: false,
      collapsible: true,
      multiSelectable: false,
    };

    const multiSelectable = accordion.getAttribute('data-multiselectable');
    if (multiSelectable) {
      options = { ...options, multiSelectable: true };
    }

    const notCollapsible = accordion.getAttribute('data-not-collapsible');
    if (notCollapsible) {
      options = { ...options, collapsible: false };
    }

    const singleAaccordion = new Handorgel(accordion, options);
  });

  // sidebar accordion
  const sidebars = document.querySelectorAll('.sidebar');
  sidebars.forEach((sidebar) => {
    if (sidebar) {
      const sidebarButton = sidebar.querySelector('.js-sidebar-button');
      const sidebarContent = sidebar.querySelector('.js-sidebar-content');

      const button = sidebar.querySelector('.handorgel__header');
      const numberOfButtons = sidebar.querySelectorAll('.handorgel__header').length;
      const buttonStyle = button.currentStyle || window.getComputedStyle(button);
      const buttonHeight = parseInt(buttonStyle.height.replace('px', ''), 10);

      const contentItemInner = sidebar.querySelector('.handorgel__content__inner');
      const contentItemInnerStyle = contentItemInner.currentStyle || window.getComputedStyle(contentItemInner);
      const contentItemInnerPadding =
        parseInt(contentItemInnerStyle.paddingTop.replace('px', ''), 10) +
        parseInt(contentItemInnerStyle.paddingBottom.replace('px', ''), 10);

      const contentItem = sidebar.querySelector('.handorgel__content__inner a');
      const numberOfContentItems = sidebar.querySelectorAll('.handorgel__content__inner a').length;
      const contentItemStyle = contentItem.currentStyle || window.getComputedStyle(contentItem);
      const contentItemHeight = parseInt(contentItemStyle.height.replace('px', ''), 10);

      const maxHeight = numberOfButtons * (buttonHeight + contentItemInnerPadding) + numberOfContentItems * contentItemHeight;

      if (!sidebar.classList.contains('closed')) {
        sidebarContent.style.maxHeight = `${maxHeight}px`;
      }

      const enableSidebarButton = () => {
        sidebarButton.addEventListener('click', () => {
          if (sidebar.classList.contains('closed')) {
            sidebar.classList.remove('closed');
            sidebarContent.style.maxHeight = `${maxHeight}px`;
          } else {
            sidebar.classList.add('closed');
            sidebarContent.style.maxHeight = '0px';
          }
        });
      };

      if (sidebarButton) {
        if (window.innerWidth <= 768) {
          enableSidebarButton();
        }
      }

      window.addEventListener('resize', () => {
        if (window.innerWidth <= 768) {
          if (!sidebar.classList.contains('closed')) {
            sidebar.classList.add('closed');
            sidebarContent.style.maxHeight = '0px';
          }

          enableSidebarButton();
        } else {
          if (sidebar.classList.contains('closed')) {
            sidebar.classList.remove('closed');
            sidebarContent.style.maxHeight = `${maxHeight}px`;
          }
        }
      });
    }
  });
}
