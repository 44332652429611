export default function voting() {
  if (document.querySelector('.voting-popup')) {
    const votingPopup = document.querySelector('.voting-popup');
    const votingYes = document.querySelectorAll('.js-voting');

    votingYes.forEach((vote) => {
      vote.addEventListener('click', () => {
        votingPopup.classList.add('shown');
        setTimeout(() => {
          votingPopup.classList.remove('shown');
        }, 10000);
      });
    });

    const votingClose = document.querySelector('.voting-popup__close');

    votingClose.addEventListener('click', () => {
      votingPopup.classList.remove('shown');
    });
  }
}
