import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import mediaQuery from '../../assets/js/helpers/match-media';
import debounce from '../../assets/js/helpers/debounce';

const defaults = {
  navigationSelector: '.js-navigation',
  drawerSelector: 'drawer',
  breakpoint: 'large',
  openOnHover: false,
  closeOnEsc: true,
};

export default class Navigation {
  constructor(options) {
    this.options = { ...defaults, ...options };

    this.navigation = document.querySelector(this.options.navigationSelector);
    this.hover = this.options.openOnHover;
    this.mediaQuery = this.options.breakpoint;
    this.esc = this.options.closeOnEsc;
    this.isTouchDevice = 'ontouchstart' in document.documentElement;
    this.dropdowns = Array.from(this.navigation.querySelectorAll('.js-has-panel'));
    this.backButtons = Array.from(this.navigation.querySelectorAll('.js-menu-back'));
    this.button = document.querySelector(`[aria-controls='${this.options.drawerSelector}']`);
    this.expanded = JSON.parse(this.button.getAttribute('aria-expanded'));
    this.drawer = document.getElementById(this.options.drawerSelector);
    this.scrollable = this.drawer.firstElementChild;
    this.resetBind = this.reset.bind(this);
    this.bodyEventBind = this.bodyEvent.bind(this);
    this.clickEventBind = this.clickEvent.bind(this);

    this.reset();
    this.initDrawer();
    this.initNavigation();
    this.back();
    this.body();
    this.resize();
    this.escape();
  }

  reset() {
    this.dropdowns.forEach((dropdown) => {
      const link = dropdown.querySelector('.js-aria-ctrl');
      const panel = dropdown.querySelector('.js-panel');
      dropdown.classList.remove('-is-expanded');
      link.setAttribute('aria-expanded', 'false');
      panel.setAttribute('aria-hidden', 'true');
      this.handleBodyAndPanelContentScroll('scroll', panel);
    });
  }

  handleBodyAndPanelContentScroll(scrollPropertyValue, panel = null) {
    const content = panel.querySelector('.js-panel-content');

    if (!mediaQuery(this.mediaQuery)) {
      if (scrollPropertyValue === 'visible') {
        disableBodyScroll(content);
      } else if (scrollPropertyValue === 'scroll') {
        enableBodyScroll(content);
      }
    } else {
      enableBodyScroll(content);
    }
  }

  clickEvent(event) {
    const element = event.currentTarget.parentElement;
    const link = element.querySelector('.js-aria-ctrl');
    const panel = element.querySelector('.js-panel');

    if (!event.target.classList.contains('js-menu-back')) {
      if (!element.classList.contains('-is-expanded')) {
        element.classList.add('-is-expanded');
        link.setAttribute('aria-expanded', 'true');
        panel.setAttribute('aria-hidden', 'false');
        this.handleBodyAndPanelContentScroll('visible', panel);
      } else {
        element.classList.remove('-is-expanded');
        link.setAttribute('aria-expanded', 'false');
        panel.setAttribute('aria-hidden', 'true');
        this.handleBodyAndPanelContentScroll('scroll', panel);
      }
    }
  }

  openEvent(event) {
    const element = event.currentTarget;
    const link = element.querySelector('.js-aria-ctrl');
    const panel = element.querySelector('.js-panel');

    element.classList.add('-is-expanded');
    link.setAttribute('aria-expanded', 'true');
    panel.setAttribute('aria-hidden', 'false');
  }

  closeEvent(event) {
    const element = event.currentTarget;
    const link = element.querySelector('.js-aria-ctrl');
    const panel = element.querySelector('.js-panel');

    element.classList.remove('-is-expanded');
    link.setAttribute('aria-expanded', 'false');
    panel.setAttribute('aria-hidden', 'true');
  }

  bodyEvent(event) {
    this.dropdowns.forEach((dropdown) => {
      if (!dropdown.contains(event.target)) {
        const link = dropdown.querySelector('.js-aria-ctrl');
        const panel = dropdown.querySelector('.js-panel');

        dropdown.classList.remove('-is-expanded');
        link.setAttribute('aria-expanded', 'false');
        panel.setAttribute('aria-hidden', 'true');
      }
    });
  }

  click(dropdown) {
    const link = dropdown.querySelector('.js-aria-ctrl');
    link.addEventListener('click', this.clickEventBind);
  }

  mouseenter(dropdown) {
    if (mediaQuery(this.mediaQuery)) {
      dropdown.addEventListener('mouseenter', this.openEvent);
    } else {
      dropdown.removeEventListener('mouseenter', this.openEvent);
    }
  }

  mouseleave(dropdown) {
    if (mediaQuery(this.mediaQuery)) {
      dropdown.addEventListener('mouseleave', this.closeEvent);
    } else {
      dropdown.removeEventListener('mouseleave', this.closeEvent);
    }
  }

  back() {
    this.backButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        const panel = e.target.parentNode;
        const dropdown = panel.parentNode;
        const link = dropdown.querySelector('.js-aria-ctrl');
        dropdown.classList.remove('-is-expanded');
        link.setAttribute('aria-expanded', 'false');
        panel.setAttribute('aria-hidden', 'true');
        this.handleBodyAndPanelContentScroll('scroll', panel);
      });
    });
  }

  body() {
    const body = document.querySelector('body');
    body.addEventListener('click', this.bodyEventBind);
  }

  initNavigation() {
    this.dropdowns.forEach((dropdown) => {
      this.click(dropdown);

      if (this.isTouchDevice === false && this.hover === true) {
        this.mouseenter(dropdown);
        this.mouseleave(dropdown);
      }
    });
  }

  showDrawer() {
    this.expanded = true;

    this.button.setAttribute('aria-expanded', 'true');
    this.drawer.setAttribute('aria-hidden', 'false');

    if (!mediaQuery(this.mediaQuery)) {
      disableBodyScroll(this.scrollable);
    }
  }

  hideDrawer() {
    this.expanded = false;

    this.button.setAttribute('aria-expanded', 'false');
    this.drawer.setAttribute('aria-hidden', 'true');

    if (!mediaQuery(this.mediaQuery)) {
      enableBodyScroll(this.scrollable);
    }
  }

  initDrawer() {
    this.button.setAttribute('aria-expanded', 'false');
    this.drawer.setAttribute('aria-hidden', 'true');

    const self = this;
    this.button.addEventListener('click', () => {
      if (!self.expanded) {
        self.showDrawer();
      } else {
        self.hideDrawer();
      }
    });
  }

  resize() {
    window.addEventListener(
      'resize',
      debounce(() => {
        this.reset();
        this.initNavigation();
        this.back();
        this.body();
        this.hideDrawer();

        // if (this.expanded && mediaQuery(this.mediaQuery)) {
        //   disableBodyScroll(this.scrollable);
        // } else {
        //   enableBodyScroll(this.scrollable);
        // }
      }, 300),
    );
  }

  escape() {
    if (this.esc) {
      document.addEventListener('keyup', (event) => {
        if (event.keyCode === 27) {
          this.hideDrawer();
          this.reset();
        }
      });
    }
  }
}
