/* eslint-disable no-unused-vars */
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

const options = {
  autoHide: true,
  timeout: 500,
};

export default function scrollbarInit() {
  const scrollbars = document.querySelectorAll('.js-scrollbar');
  scrollbars.forEach((scrollbar) => {
    const singleScrollbar = new SimpleBar(scrollbar, options);
  });
}
